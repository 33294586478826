import {useRouter} from 'next/router';
import React from 'react';

import LoggedInUserOnlyGuard from '@app/components/guards/LoggedInUserOnlyGuard';

const IndexContainer = () => {
  const router = useRouter();

  React.useEffect(() => {
    router.replace('/course/now');
  }, [router]);

  return null;
};

const IndexPage = () => {
  return (
    <LoggedInUserOnlyGuard>
      <IndexContainer />
    </LoggedInUserOnlyGuard>
  );
};

export default IndexPage;

import {useRouter} from 'next/router';
import React from 'react';
import {useAsync} from 'react-use';

import * as UserFacade from '@app/facade/user';
import useUser from '@app/hooks/user/useUser';
import {useQueryState} from '@app/misc/query-state';
import OnboardingCompletedProfileGuard from './OnboardingCompletedProfileGuard';

// FIXME: https://riiid-pioneer.atlassian.net/browse/TN-2600
const NonAnonymousOnly = ({children}: {children?: React.ReactNode}): React.ReactElement | null => {
  const isRedirectedRef = React.useRef(false);

  const router = useRouter();
  const queryState = useQueryState();
  const {data: user, isFetching, isSuccess} = useUser({suspense: true});

  const isAnonymousUser = isSuccess && UserFacade.getIsUserAnonymous(user);

  const shouldGoToOnboardingIntroPage = isAnonymousUser;

  useAsync(async () => {
    if (isFetching || isRedirectedRef.current) {
      return;
    }

    if (shouldGoToOnboardingIntroPage) {
      await router.replace({pathname: '/onboarding/intro', query: queryState.toQuery()});
    }

    isRedirectedRef.current = true;
  }, [router, isFetching, shouldGoToOnboardingIntroPage]);

  return shouldGoToOnboardingIntroPage ? null : (
    // TODO: 추후 분리하기
    <OnboardingCompletedProfileGuard>{children}</OnboardingCompletedProfileGuard>
  );
};

const LoggedInUserOnlyGuard = ({children}: {children?: React.ReactNode}) => {
  return <NonAnonymousOnly>{children}</NonAnonymousOnly>;
};

export default LoggedInUserOnlyGuard;

import {useRouter} from 'next/router';
import React from 'react';

import {useAsync} from 'react-use/lib';
import * as UserFacade from '@app/facade/user';
import useUserProfile from '@app/hooks/user/useUserProfile';
import {useQueryState} from '@app/misc/query-state';

interface OnboardingCompletedProfileGuardProps {
  children?: React.ReactNode;
}

const OnboardingCompletedProfileGuard = ({children}: OnboardingCompletedProfileGuardProps) => {
  const isRedirectedRef = React.useRef(false);

  const router = useRouter();
  const queryState = useQueryState();
  const {data: profile, isFetching} = useUserProfile({suspense: true});

  const hasProfileDoneOnboarding = UserFacade.isUserOnboardingDone(profile);
  const isPathnameOnboarding = router.pathname === '/onboarding';

  const shouldGoToOnboardingPage = !hasProfileDoneOnboarding && !isPathnameOnboarding;
  const shouldGoToIndexPage = hasProfileDoneOnboarding && isPathnameOnboarding;

  useAsync(async () => {
    if (isFetching || isRedirectedRef.current) {
      return;
    }

    if (shouldGoToOnboardingPage) {
      await router.replace({pathname: '/onboarding', query: queryState.toQuery()});
    } else if (shouldGoToIndexPage) {
      await router.replace({pathname: '/', query: queryState.toQuery()});
    }

    isRedirectedRef.current = true;
  }, [router, isFetching, shouldGoToIndexPage, shouldGoToOnboardingPage]);

  return shouldGoToIndexPage || shouldGoToOnboardingPage ? null : <>{children}</>;
};

export default OnboardingCompletedProfileGuard;
